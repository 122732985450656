import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",staticStyle:{"margin-top":"100px","min-height":"80vh"}},[_c(VRow,[_c(VCol,{staticClass:"pa-0"},[_c(VImg,{attrs:{"src":require('@/assets/img/misc/page-not-found.svg'),"max-height":"400"}})],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c('div',{staticClass:"text-center mt-6"},[_c('p',{staticClass:"four-o-four primary--text"},[_vm._v("404")]),_c('p',{staticClass:"go-back-home primary--text"},[_vm._v("Page not found")]),_c(VBtn,{attrs:{"large":"","to":"/","color":"secondary darken-2","dark":""}},[_vm._v("Go To Home")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }